<template>
    <lazy-component class="contactUsViwerDiv">
      <div class="contactUsViwerMainDiv">
        <div class="contactUsViwerSubDiv">
          <div class="ContantUsDivParent">
            <div class="ContantUsDiv font-new">
              Can't decide? We're here to help.
            </div>
            <div class="ContantUsDiv2 font-new">
              We'll have one of concierge team contact you.
            </div>
          </div>
          <div class="ContactBtn font-new"  @click="gotoContact">
            Contact Us
          </div>
          <div class="ContactImgDiv">
            <img class="ContactImg" src="../../assets/images/contactus_bakcground.png">
          </div>
        </div>
      </div>
    </lazy-component>
</template>
<script>
export default {
    
    data() {
        return {

        }
    },
    methods: {
        gotoContact(){
            this.$router.push({ path: '/contact/'})
        },
    },
}
</script>