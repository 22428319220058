<template>
  <lazy-component class="resortsMain">
    <div class="resortsMainFirst">
      <img
        class="resortsMainBackground"
        src="../assets/images/resort_background.png"
      />
      <div class="resortsMainBackgroundDiv">
        <div class="resortTitleDiv">MALDIVES LUXURY RESORTS</div>
      </div>
    </div>
    <div class="resortSelectMainDiv">
      <div v-if="$mq === 'lg' || $mq === 'md'" class="resortSelectMainDiv1">
        <div class="resortSelectMainCellDiv">
          <div class="resortTravelSelectDiv">
            <a-select
              v-model="filterBestFor"
              class="resortSelectTravel"
              @change="handleFilterChangeTravel"
              :filter-option="filterOption"
              @blur="onBlurTravel"
              @dropdownVisibleChange="dropdownVisibleChange"
              mode="tags"
              placeholder="Type of travel "
              :size="'large'"
            >
              <a-select-option
                v-for="_item in bestFor"
                :key="_item"
                :value="_item"
              >
                <div class="resortMainSelectCellDiv">
                  <span class="resortMainSelectCell">{{ _item }}</span>
                </div>
              </a-select-option>
            </a-select>
            <a-icon
              class="resortSelectTravelIcon"
              type="down"
              :style="
                isDropdown
                  ? {
                      fontSize: '20px',
                      color: '#787878',
                      'margin-top': '-5px',
                      transform: 'rotate(180deg)',
                    }
                  : {
                      fontSize: '20px',
                      color: '#787878',
                      'margin-top': '-5px',
                      transform: 'rotate(0deg)',
                    }
              "
            />
          </div>
          <div class="resortVerticalLine"></div>
          <a-select
            class="resortMainSelect"
            v-model="filterResortStyle"
            @change="handleFilterChange"
            :size="'large'"
          >
            <a-icon
              slot="suffixIcon"
              type="down"
              :style="{
                fontSize: '20px',
                color: '#787878',
                'margin-top': '-5px',
              }"
            />
            <a-select-option
              v-for="(_style, index) in resortStyle"
              :key="_style"
              :value="index"
            >
              <div class="resortMainSelectCellDiv">
                <span class="resortMainSelectCell">{{ _style }}</span>
              </div>
            </a-select-option>
          </a-select>

          <div class="resortVerticalLine"></div>
          <a-select
            class="resortMainSelect"
            v-model="filterResortSize"
            @change="handleFilterChange"
            :size="'large'"
          >
            <a-icon
              slot="suffixIcon"
              type="down"
              :style="{
                fontSize: '20px',
                color: '#787878',
                'margin-top': '-5px',
              }"
            />
            <a-select-option
              v-for="(_size, index) in resortSizes"
              :key="_size"
              :value="index"
            >
              <div class="resortMainSelectCellDiv">
                <div class="resortMainSelectCell">{{ _size }}</div>
              </div>
            </a-select-option>
          </a-select>

          <div class="resortMapView" @click="viewMapView">
            <span>Map view</span>
            <img src="../assets/images/button_map.png" />
          </div>
        </div>
      </div>
      <div v-else class="resortSelectMainDiv1">
        <div class="resortSelectMainDiv2">
          <div class="homeSearchMainSub2">
            <div
              class="homeSearchMainSub2Text"
              @click="gotoHotelSearchClick(true)"
            >
              Search
            </div>
            <img
              class="homeSearchMainSub2Icon"
              src="../assets/images/icon_search_white.png"
              alt=""
              width="29px"
            />
          </div>
          <div
            class="homeSearchMainSub3"
            v-bind:style="[
              showSearchFilterMobile
                ? { border: '2px solid #CEB39A', 'background-color': '#00C2CB' }
                : {},
            ]"
            @click="clickSearchFilterMobile"
          >
            <img
              style="margin: auto"
              src="../assets/images/icon_filter.png"
              alt=""
              width="28px"
            />
          </div>
        </div>
        <div v-if="showSearchFilterMobile" class="resortSelectMainDiv3">
          <div class="resortMainSelectDiv">
            <a-select
              class="resortMainSelect"
              v-model="filterResortStyle"
              @change="handleFilterChange"
              :size="'large'"
            >
              <a-icon
                slot="suffixIcon"
                type="down"
                :style="{ fontSize: '20px', color: '#787878' }"
              />
              <a-select-option
                v-for="(_style, index) in resortStyle"
                :key="_style"
                :value="index"
              >
                <div class="resortMainSelectCellDiv">
                  <span class="resortMainSelectCell">{{ _style }}</span>
                </div>
              </a-select-option>
            </a-select>
          </div>
          <div class="resortMainSelectDiv">
            <a-select
              class="resortMainSelect"
              v-model="filterResortSize"
              @change="handleFilterChange"
              :size="'large'"
            >
              <a-icon
                slot="suffixIcon"
                type="down"
                :style="{
                  fontSize: '20px',
                  color: '#787878',
                  'margin-top': '-5px',
                }"
              />
              <a-select-option
                v-for="(_size, index) in resortSizes"
                :key="_size"
                :value="index"
              >
                <div class="resortMainSelectCellDiv">
                  <span class="resortMainSelectCell">{{ _size }}</span>
                </div>
              </a-select-option>
            </a-select>
          </div>
          <!-- <div class="resortMainSelectDiv">
            <a-select class="resortMainSelect" v-model="filterResortBudget" @change="handleFilterChange" :size="'large'" >
              <a-icon slot="suffixIcon" type="down" :style="{ fontSize: '20px', color: '#787878', 'margin-top':'-5px' }"/>
              <a-select-option v-for="(_item, index) in budget" :key="_item" :value="index">
                <div class="resortMainSelectCellDiv">
                  <span class="resortMainSelectCell">{{ _item }}</span>
                </div>
              </a-select-option>
            </a-select>
          </div> -->
          <div class="resortTravelSelectDiv">
            <a-select
              class="resortSelectTravel"
              v-model="filterBestFor"
              @change="handleFilterChangeTravel"
              :filter-option="filterOption"
              @blur="onBlurTravel"
              @dropdownVisibleChange="dropdownVisibleChange"
              mode="tags"
              placeholder="Type of travel"
              :size="'large'"
            >
              <a-select-option
                v-for="_item in bestFor"
                :key="_item"
                :value="_item"
              >
                <div class="resortMainSelectCellDiv">
                  <span class="resortMainSelectCell">{{ _item }}</span>
                </div>
              </a-select-option>
            </a-select>
            <a-icon
              class="resortSelectTravelIcon"
              type="down"
              :style="
                isDropdown
                  ? {
                      fontSize: '20px',
                      color: '#787878',
                      'margin-top': '-5px',
                      transform: 'rotate(180deg)',
                    }
                  : {
                      fontSize: '20px',
                      color: '#787878',
                      'margin-top': '-5px',
                      transform: 'rotate(0deg)',
                    }
              "
            />
          </div>
          <div class="resortMapView" @click="viewMapView">
            <span>Map view</span>

            <img src="../assets/images/button_map.png" />
          </div>
        </div>
      </div>
      <div class="resortMainListDiv">
        <a-list
          v-if="!loading && !filtering && filterResult.length > 0"
          :grid="{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }"
          type="flex"
          :data-source="filterResult"
        >
          <a-list-item
            slot="renderItem"
            slot-scope="item"
            class="resortMainListItem"
          >
            <div class="resortCell">
              <div class="resortCellDiv">
                <div class="resortCellImgDiv">
                  <img
                    class="resortCellImg"
                    :src="
                      item.hotelimage != '' && item.hotelimage != undefined
                        ? `${item.hotelimage}`
                        : ''
                    "
                    alt=""
                  />
                </div>
                <!-- <div style="position:absolute;width:100%;bottom:0px">
                  <div style="position: absolute;bottom: 7px;color: #000000;font-style: normal;font-weight: 500;font-size: 15px;line-height: 26px;text-align:left;margin-left:15px;margin-top:5px;text-transform: uppercase;font-family: OpenSans;color: #FFFFFF;">{{item.hotelname}}</div> -->
                <!-- <div style="display: contents;"> -->
                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 350">
                      <path stroke="null" id="svg_1" d="m-1415.99991,-93l119.54166,46.55556c119.54166,46.55556 358.62497,139.66667 597.70828,139.66667c239.08331,0 478.16662,-93.11111 717.24993,-93.11111c239.08331,0 478.16662,93.11111 717.24993,139.66667c239.08331,46.55556 478.16662,46.55556 597.70828,46.55556l119.54166,0l0,139.66667l-119.54166,0c-119.54166,0 -358.62497,0 -597.70828,0c-239.08331,0 -478.16662,0 -717.24993,0c-239.08331,0 -478.16662,0 -717.24993,0c-239.08331,0 -478.16662,0 -597.70828,0l-119.54166,0l0,-419z"
                      fill="#ceb39acc"/>
                    </svg> -->
                <!-- <div style="height: 40px;width: 100%;background-color: #ceb39acc;"></div> -->

                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
                      <g stroke="null">
                        <title stroke="null">Layer 1</title>
                        <g opacity="0.8" stroke="null" id="svg_3">
                        <path stroke="null" id="svg_1" d="m-1685.27277,-45.99999l138.69697,23.8495c138.69697,23.8495 416.09088,71.54851 693.4848,71.54851c277.39392,0 554.78784,-47.699 832.18176,-47.699c277.39392,0 554.78784,47.699 832.18176,71.54851c277.39392,23.8495 554.78784,23.8495 693.4848,23.8495l138.69697,0l0,71.54851l-138.69697,0c-138.69697,0 -416.09088,0 -693.4848,0c-277.39392,0 -554.78784,0 -832.18176,0c-277.39392,0 -554.78784,0 -832.18176,0c-277.39392,0 -554.78784,0 -693.4848,0l-138.69697,0l0,-214.64553l0,-0.00001l0,0.00002z" fill="#ceb39a"/>
                        <rect stroke="null" fill="#ceb39a" x="-201.81854" y="152.25307" width="1850.9098" height="308.56522" id="svg_2"/>
                        </g>
                      </g>
                    </svg> -->
                <!-- </div>
                </div> -->
              </div>

              <div class="resortCellDivShadow"></div>

              <div class="resortCellContentDiv">
                <div class="resortHotelName">{{ item.hotelname }}</div>

                <div
                  style="margin-left: 0px; margin-right: 0px"
                  v-if="item.hotelproperty"
                >
                  <div class="resortsItemTitle">Best for</div>
                  <div class="resortsItemDetail">
                    {{ item.hotelproperty.bestFor }}
                  </div>
                </div>
                <div
                  v-if="$mq === 'lg' || $mq === 'md'"
                  class="resortTravelListDiv"
                >
                  <div
                    v-for="(column, index) in columnsTravel(
                      item.hoteldatarooms.typeoftravel
                    )"
                    class="resortTravelListCell"
                    :key="index"
                  >
                    <div
                      style="margin-right: 20px"
                      :key="item"
                      v-for="item in column"
                    >
                      <div style="display: flex">
                        <img
                          style="margin-top: auto; margin-bottom: auto"
                          src="../assets/images/checkIcon.png"
                          alt=""
                          width="12"
                        />
                        <div class="resortOfferItem">{{ item }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="resortTravelListDiv">
                  <div
                    v-for="(column, index) in item.hoteldatarooms.typeoftravel"
                    :key="index"
                    class="resortTravelListCell1"
                  >
                    <div style="display: flex; width: 100%">
                      <img
                        style="margin-top: auto; margin-bottom: auto"
                        src="../assets/images/checkIcon.png"
                        alt=""
                        width="12"
                      />
                      <div class="resortOfferItem">{{ column }}</div>
                    </div>
                  </div>
                </div>

                <a
                  :href="'/resort/' + decodeURIComponent(item.hotelname).replace(/\s/g, '-').toLowerCase()"
                  class="homeViewResort"
                  style="margin-left: 0px; margin-top: 10px; margin-bottom: 5px"
                  target="_blank"
                >
                  View Resort
                </a>
              </div>
            </div>
          </a-list-item>
        </a-list>

        <div v-else-if="!loading && !filtering && filterResult.length <= 0">
          <div style="text-align: center; margin-top: 50px">
            <svg
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                <ellipse
                  fill="#F5F5F5"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g fillRule="nonzero" stroke="#D9D9D9">
                  <path
                    d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                  ></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#FAFAFA"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="resortEmptyDiv">
            Apologies as we do not have a property that matches your criteria.
            Please select another option or contact our Concierge via chat to
            assist you further.
          </div>
        </div>
        <div v-else class="resortGifDiv">
          <img
            class="imageHotelDetail"
            :src="'../images/logo.gif'"
            alt=""
            width="100%"
            height="100%"
            loop="infinite"
          />
        </div>
      </div>
    </div>

    <contactus-viewer></contactus-viewer>
    <instagram-viewer style="padding-bottom: 100px"></instagram-viewer>
  </lazy-component>
</template>
<script>
import { api } from "../helpers/Helpers";
// import carousel from 'vue-owl-carousel'
import ContactusViewer from "./ListItems/contactus-viewer.vue";
import InstagramViewer from "./ListItems/instagram-viewer.vue";
const resortSizes = [
  "Resort size",
  "Intimate (less < 50 rooms)",
  "Moderate (more > 50 rooms)",
  "Extensive (more > 100 rooms)",
];
const resortStyle = [
  "Resort style",
  // 'Modern',
  // 'Barefoot luxury',
  // 'Rustic chic',
  // 'Ultra-luxury',
  // 'Elegant',
  // 'Maldivian & Modern',
  // 'Maldivian rustic',
];
const bestFor = [
  // 'Type of travel',
  // 'Romance',
  // 'Family',
  // 'Friends getaway',
  // 'Solo traveler',
  // 'Foodies',
  // 'Spa & wellness',
  // 'Wellness retreat',
  // 'All-inclusive',
  // 'Best for diving',
  // 'Great house reef',
  // 'Snorkeling',
  // 'Quick airport transfer',
  // 'Underwater dining',
  // 'Overwater villas only',
  // 'Adults-only resort',
  // 'Best for surfing',
  // 'Remote and secluded',
];
const budget = [
  "Budget per night",
  "$500 - $1500",
  "$1500 - $2500",
  "$2500 - $3500",
  "$3500 +",
];
const hotelList = [];

export default {
  metaInfo: {
    // Children can override the title.
    title: "VUE Collection Resorts",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site

    titleTemplate: "%s",

    //titleTemplate: '%s ← My maldives',
    // Define meta tags here.
    meta: [
      {
        name: "description",
        content:
          "A handpicked collection of the most unique and beautiful resorts in the Collection.",
      },

      // {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      // {name: 'description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},

      // // OpenGraph data (Most widely used)
      // {property: 'og:title', content: 'VUE Collection Resorts'},
      // {property: 'og:site_name', content: 'VUE Collection Resorts'},
      // // The list of types is available here: http://ogp.me/#types
      // {property: 'og:type', content: 'website'},
      // // Should the the same as your canonical link, see below.
      // {property: 'og:url', content: 'https://vuemaldives.com/resorts'},
      // {property: 'og:image', content: 'https://vuemaldives.com/images/logo1.png'},
      // // Often the same as your meta description, but not always.
      // {property: 'og:description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},

      // // Twitter card
      // {name: 'twitter:card', content: 'summary'},
      // {name: 'twitter:site', content: 'https://vuemaldives.com/resorts'},
      // {name: 'twitter:title', content: 'VUE Collection Resorts'},
      // {name: 'twitter:description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},
      // // Your twitter handle, if you have one.
      // {name: 'twitter:creator', content: '@alligatorio'},
      // {name: 'twitter:image:src', content: 'https://vuemaldives.com/images/logo1.png'},

      // // Google / Schema.org markup:
      // {itemprop: 'name', content: 'VUE Collection Resorts'},
      // {itemprop: 'description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},
      // {itemprop: 'image', content: 'https://vuemaldives.com/images/logo1.png'}
    ],

    link: [{ rel: "canonical", href: "https://vuemaldives.com/resorts" }],
  },
  data() {
    return {
      hotelList,
      filterResult: [],
      resortSizes,
      resortStyle,
      bestFor,
      budget,

      filterResortBudget: 0,
      filterResortSize: 0,
      filterResortStyle: 0,
      filterBestFor: [],

      isDropdown: false,

      loading: true,
      filtering: false,

      showSearchFilterMobile: false,
    };
  },

  components: {
    // carousel,
    ContactusViewer,
    InstagramViewer,
  },
  mounted() {
    this.initDate();
  },
  methods: {
    initDate: async function () {
      await this.getResortStyles();
      await this.getResortTypeTravels();
      await this.getHotelList();
    },
    getResortStyles: async function () {
      const res = await api.getResortStyleInfo();
      if (res.length > 0) {
        this.resortStyle = ["Resort style"];
        for (let i = 0; i < res[0].datalist.length; i++) {
          this.resortStyle.push(res[0].datalist[i].name);
        }
      }
    },
    getResortTypeTravels: async function () {
      const res = await api.getResortTypeTravelInfo();
      if (res.length > 0) {
        this.bestFor = [];
        for (let i = 0; i < res[0].datalist.length; i++) {
          this.bestFor.push(res[0].datalist[i].name);
        }
      }
    },

    filterOption(input, option) {
      const bestForInfo = this.bestFor.filter((obj) => obj == option.key);
      if (bestForInfo.length > 0) {
        return bestForInfo[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
    onBlurTravel() {
      for (let i = 0; i < this.filterBestFor.length; i++) {
        const bestForInfo = this.bestFor.filter(
          (obj) => obj == this.filterBestFor[i]
        );
        if (bestForInfo.length <= 0) {
          this.filterBestFor.splice(i, 1);
        }
      }
    },
    handleFilterChangeTravel() {
      for (let i = 0; i < this.filterBestFor.length; i++) {
        const bestForInfo = this.bestFor.filter(
          (obj) => obj == this.filterBestFor[i]
        );
        if (bestForInfo.length <= 0) {
          this.filterBestFor.splice(i, 1);
        }
      }
      this.handleFilterChange();
    },
    handleFilterChange() {
      this.filtering = true;
      this.filterResult = this.hotelList;

      if (this.filterResortBudget == 1) {
        this.filterResult = this.filterResult.filter(
          (obj) => obj.hoteldatageneral.reqularprice <= 1500
        );
      } else if (this.filterResortBudget == 2) {
        this.filterResult = this.filterResult.filter(
          (obj) =>
            obj.hoteldatageneral.reqularprice > 1500 &&
            obj.hoteldatageneral.reqularprice <= 2500
        );
      } else if (this.filterResortBudget == 3) {
        this.filterResult = this.filterResult.filter(
          (obj) =>
            obj.hoteldatageneral.reqularprice > 2500 &&
            obj.hoteldatageneral.reqularprice <= 3500
        );
      } else if (this.filterResortBudget == 4) {
        this.filterResult = this.filterResult.filter(
          (obj) => obj.hoteldatageneral.reqularprice > 3500
        );
      }

      if (this.filterResortSize == 1) {
        this.filterResult = this.filterResult.filter(
          (obj) => obj.hoteldatarooms.numberofroom < 50
        );
      } else if (this.filterResortSize == 2) {
        this.filterResult = this.filterResult.filter(
          (obj) =>
            obj.hoteldatarooms.numberofroom > 50 &&
            obj.hoteldatarooms.numberofroom < 100
        );
      } else if (this.filterResortSize == 3) {
        this.filterResult = this.filterResult.filter(
          (obj) => obj.hoteldatarooms.numberofroom > 100
        );
      }

      if (this.filterResortStyle != 0) {
        this.filterResult = this.filterResult.filter((obj) =>
          obj.hoteldatarooms.stype.includes(
            this.resortStyle[this.filterResortStyle]
          )
        );
      }
      if (this.filterBestFor.length > 0) {
        var intList = this.filterBestFor; //this.getResortBestIntList(this.filterBestFor);
        this.filterResult = this.filterResult.filter((obj) =>
          intList.every((cat) => obj.hoteldatarooms.typeoftravel.includes(cat))
        );
      }
      setTimeout(() => {
        this.filtering = false;
      }, 500);
    },
    dropdownVisibleChange(_isDropdown) {
      this.isDropdown = _isDropdown;
    },
    getHotelList: async function () {
      this.loading = true;
      let res = await api.getHotelsTotal();

      res = res.filter((item) => !(item.hotelHidden == true));
      res.sort(function (a, b) {
        let aValue = a["hotelname"];
        let bValue = b["hotelname"];
        return aValue > bValue ? 1 : bValue > aValue ? -1 : 0;
      });
      this.hotelList = res;
      this.filterResult = this.hotelList;
      this.loading = false;
    },
    getResortSize(_key) {
      const resortSizeList = {
        1: "Intimate (less < 50 rooms)",
        2: "Moderate (more > 50 rooms)",
        3: "Extensive (more > 100 rooms)",
      };
      return resortSizeList[_key];
    },

    // getResortStyleStr(_array) {
    //     const resortStyleList = {

    //         0:'Modern',
    //         1:'Barefoot luxury',
    //         2:'Rustic chic',
    //         3:'Ultra-luxury',
    //         4:'Elegant',
    //         5:'Maldivian & Modern',
    //         6:'Maldivian rustic',

    //     }
    //     var _arratStr = [];
    //     for(let i = 0; i < _array.length; i++){
    //       if (_array[i] in resortStyleList)
    //         _arratStr.push(resortStyleList[_array[i]])
    //     }
    //     return _arratStr.join(" | ");
    // },

    // getResortBestForStr(_array) {
    //     const resortList = {
    //         0:'Romance',
    //         1:'Family',
    //         2:'Friends getaway',
    //         3:'Solo traveler',
    //         4:'Foodies',
    //         5:'Spa & wellness',
    //         6:'Wellness retreat',
    //         7:'All-inclusive',
    //         8:'Best for diving',
    //         9:'Great house reef',
    //         10:'Snorkeling',
    //         11:'Quick airport transfer',
    //         12:'Underwater dining',
    //         13:'Overwater villas only',
    //         14:'Adults-only resort',
    //         15:'Best for surfing',
    //         16:'Remote and secluded',

    //     }
    //     var _arratStr = [];
    //     for(let i = 0; i < _array.length; i++){
    //       if (_array[i] in resortList)
    //         _arratStr.push(resortList[_array[i]])
    //     }
    //     return _arratStr.join(" | ");
    // },

    // getResortBestForList(_array) {
    //     const resortList = {
    //         0:'Romance',
    //         1:'Family',
    //         2:'Friends getaway',
    //         3:'Solo traveler',
    //         4:'Foodies',
    //         5:'Spa & wellness',
    //         6:'Wellness retreat',
    //         7:'All-inclusive',
    //         8:'Best for diving',
    //         9:'Great house reef',
    //         10:'Snorkeling',
    //         11:'Quick airport transfer',
    //         12:'Underwater dining',
    //         13:'Overwater villas only',
    //         14:'Adults-only resort',
    //         15:'Best for surfing',
    //         16:'Remote and secluded',

    //     }
    //     var _arratStr = [];
    //     for(let i = 0; i < _array.length; i++){
    //       if (_array[i] in resortList)
    //         _arratStr.push(resortList[_array[i]])
    //     }
    //     return _arratStr;
    // },
    columnsTravel(_items) {
      // const resortList = {
      //     0:'Romance',
      //     1:'Family',
      //     2:'Friends getaway',
      //     3:'Solo traveler',
      //     4:'Foodies',
      //     5:'Spa & wellness',
      //     6:'Wellness retreat',
      //     7:'All-inclusive',
      //     8:'Best for diving',
      //     9:'Great house reef',
      //     10:'Snorkeling',
      //     11:'Quick airport transfer',
      //     12:'Underwater dining',
      //     13:'Overwater villas only',
      //     14:'Adults-only resort',
      //     15:'Best for surfing',
      //     16:'Remote and secluded',

      // }
      // var _arratStr = [];

      // for(let i = 0; i < _items.length; i++){
      //   if (_items[i] in resortList)
      //     _arratStr.push(resortList[_items[i]])
      // }

      let columns = [];
      const cols = 2;
      let mid = Math.ceil(_items.length / cols);
      for (let col = 0; col < cols; col++) {
        columns.push(_items.slice(col * mid, col * mid + mid));
      }
      return columns;
    },

    // getResortBestIntList(_array) {
    //     const resortList = {
    //         'Romance':0,
    //         'Family':1,
    //         'Friends getaway':2,
    //         'Solo traveler':3,
    //         'Foodies':4,
    //         'Spa & wellness':5,
    //         'Wellness retreat':6,
    //         'All-inclusive':7,
    //         'Best for diving':8,
    //         'Great house reef':9,
    //         'Snorkeling':10,
    //         'Quick airport transfer':11,
    //         'Underwater dining':12,
    //         'Overwater villas only':13,
    //         'Adults-only resort':14,
    //         'Best for surfing':15,
    //         'Remote and secluded':16,
    //     }
    //     var _arratStr = [];
    //     for(let i = 0; i < _array.length; i++){
    //       if (_array[i] in resortList)
    //         _arratStr.push(resortList[_array[i]])
    //     }
    //     return _arratStr;
    // },

    gotoResortDetail(_info) {
      let hotelName = decodeURIComponent(_info.hotelname)
        .replace(/\s/g, "-")
        .toLowerCase();
      // let hotelId = _info._id;

      let route = {
        name: "hotelDetail",
        path: "/hotelDetail",
        params: { hotelname: hotelName },
      };

      window.open(this.$router.resolve(route).href, "_blank");
    },
    viewMapView() {
      const _url =
        "https://www.google.com/maps/d/u/0/viewer?mid=11G3o_eIu2ikZA0ltLthhKG6Cfeywzsk&ll=0.2787212999999951%2C73.35723870000001&z=8";
      window.open(_url, "_blank");
    },

    clickSearchFilterMobile() {
      this.showSearchFilterMobile = !this.showSearchFilterMobile;
    },
  },
};
</script>
<style scoped>
.ant-select-selection-selected-value .selectedCell {
  white-space: nowrap !important;
}
.resortCell {
  opacity: 1;
  transform: scale(1);
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px,
    rgb(0 0 0 / 5%) 0px 4px 6px -2px;
  border: 1px solid #e7e7e7;
  height: 100%;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 12px;
}
.imageHotelDetail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .resortCell {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.resortCell:hover {
  opacity: 0.9;
  transform: scale(1.03);
}
.resortCell:active {
  opacity: 1;
  transform: scale(1.04);
}
</style>
