<template>
    <lazy-component class="instagramViewerDiv">
      <div class="instagramViewerMainDiv">
        <div class="instagramViewerTitleDiv">
          <div class="homeInstagramSubTitle font-new">Instagram Inspiration</div>
          <div class="homeInstagramSubBtnDiv">
            <a href="https://www.instagram.com/vuecollection/" target="_blank" class="homeInstagramSubBtn font-new" >
              Follow Us
            </a>
          </div>
        </div>
        
        <div class="instagramViewerCarouselDiv">
          <div class="instagramCarouselDiv">
            <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag"></a></div>
    
            <!-- <carousel 
              class="instagramCarouselMainDiv"
              :key="refresh"
              :autoplay="false"
              :nav="false"
              :dots="$mq != 'lg' && $mq != 'md'"
              :rewind="true"
              :responsive="{
                0: {
                  items: 1,
                },
                768: {
                  items: 5,
                },
              }"
            >
              <div class="instagramCarouselSubDiv"
              v-for="(info, n) in instagramInfo" :key="n" >
                <div class="instagramImageDiv">
                  <div class="instagramImageDivChild">
                    <div style="margin:auto">
                      <a-icon type="heart" class="instagramImageHertIcon" @click="currentSlide(n)"/>
                      <a-icon type="message" class="instagramImageMsgIcon" @click="currentSlide(n)"/>
                    </div>
                  </div>
                  <video v-if="info.media_type == 'VIDEO'" class="instagramVideo" :src="info.media_url" ></video>
                  <img v-else class="instagramImage" :src="info.media_url" >
                </div>
              </div>
                
            </carousel> -->
          </div>
        </div>
      </div>
    </lazy-component>
</template>
<script>
// import { api } from '../../helpers/Helpers';
// import carousel from 'vue-owl-carousel'
// const instaAccessToken = "IGQVJXbXR2V2tKNjZAuZADdSMHFKX2pMUEU2cU94V2NCWkN1YWhfckRsbzVqOFdKOFN0ZAXl4N1dTRHg1RXg0bW1ielc4ZA1hNbXZAfLW4xSE00NGM5VnpwWHEwTkp2eVJIWmdWT2RxelJoSkQtbWlPMEdNZAAZDZD";

export default {
    
  data() {
    return {
      // instaAccessToken,
      // refresh:0,
      // instagramInfo:[
      //   {
      //     id: '',
      //     media_type: '', 
      //     media_url: '', 
      //     permalink: ''
      //   }
      // ],

    }
  },
  components: {
    // carousel,
  },
  mounted(){
      // this.handleGetInstagram();
  },
  methods: {
    // handleGetInstagram: async function() {
    //     const res = await api.getInstagramInfo(this.instaAccessToken);
    //     if(res != undefined){
    //       this.instagramInfo = res.data;
    //       if (this.refresh > 100){
    //         this.refresh = 0
    //       }
    //       this.refresh++
    //     }
    // },
    // currentSlide(n){
    //     window.open(this.instagramInfo[n].permalink,"_blank");// "_self"
    // },
  },
}
</script>